import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
export class Login extends Component {
    static displayName = Login.name;
    constructor() {
        super();

        this.state = {
            Email: '',
            Password: ''
        }

        this.Password = this.Password.bind(this);
        this.Email = this.Email.bind(this);
        this.login = this.login.bind(this);
    }

    Email(event) {
        this.setState({ Email: event.target.value })
    }
    Password(event) {
        this.setState({ Password: event.target.value })
    }
    login(event) {
        debugger;

        var formData = "Email=" + encodeURIComponent(this.state.Email);
        formData += "&Password=" + encodeURIComponent(this.state.Password);

        fetch('userLogin', {
            method: 'post',
            headers: {
                'Accept': 'application/x-www-form-urlencoded',  // 'application/json' if using Post([FromBody] Login user) in controller
                'Content-Type': 'application/x-www-form-urlencoded'  // 'application/json' if using Post([FromBody] Login user) in controller
            },
            body: formData
            /*
            body: JSON.stringify({
                Email: this.state.Email,
                Password: this.state.Password
            })
            */
        }).then((Response) => Response.json())
            .then((result) => {
                console.log(result);
                if (result == 'Invalid') {
                    window.localStorage.setItem('isCofareAdmin', false);
                    alert('Incorrect Login or Password.');
                } else {
                    window.localStorage.setItem('isCofareAdmin', true);
                    this.props.history.push("/view-transactions");
                }
            })
    }

    render() {

        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="9" lg="7" xl="6">

                            <CardGroup>
                                <Card className="p-2">
                                    <CardBody>
                                        <Form>
                                            <div className="row" className="mb-2 pageheading">
                                                <div className="col-sm-12 btn btn-primary">
                                                    Login
                                                </div>
                                            </div>
                                            <InputGroup className="mb-3">

                                                <Input type="text" onChange={this.Email} placeholder="Enter Email" />
                                            </InputGroup>
                                            <InputGroup className="mb-4">

                                                <Input type="password" onChange={this.Password} placeholder="Enter Password" />
                                            </InputGroup>
                                            <Button onClick={this.login} color="success" block>Login</Button>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
