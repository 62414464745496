import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
      <div>
        <h1>Monitoring Cofare Transactions</h1>
        <p>Welcome to your new single-page application, built with:</p>
        <ul>
          <li><a href='https://get.asp.net/'>ASP.NET Core</a> and <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> for cross-platform server-side code</li>
          <li><a href='https://facebook.github.io/react/'>React</a> for client-side code</li>
          <li><a href='http://getbootstrap.com/'>Bootstrap</a> for layout and styling</li>
        </ul>
        <p>A few instances were noted in the past where a user who bought tickets at https://cofare.io were charged twice. Investigation discovered the following:</p>
        <ul>
          <li>The two charges have different transaction ID. and were a few minutes or a few hours apart.</li>
          <li>Cofare had no record about the transaction ID associated with earlier time.</li>
          <li>According to one of the customer, she placed an order but did not receive confirmation message. She waited a few hours for email or Text, but nothing came. So she went to place the order again.</li>
        </ul>
        <p>Our conclusion is the Cofare server encountered an error condition and did not complete recording the transaction. While we still don't know exactly what was the error, these are the posibilities:</p>
            <ul>
                <li>Internal sofware or hardware error, causing webstie crash.</li>
                <li>External communication interrupt causing confirmaiton of successful charge not received.</li>
                <li>Transaction Logic Error. <b>e.g. If for whatever reason, unable to send confirmation email to user, server did not record the charge.</b></li>
            </ul>
        <h1>Resolution</h1>
            <p>We provide <a href='view-transactions'>this utility</a> to help identify problem transactions and manually make corrections. We continue to collection information about details of the error condition, we will make chagnes to the application to prevent runaway charge in the first place.</p>
      </div>
    );
  }
}
