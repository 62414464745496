import React, { Component } from 'react';
import { Input } from 'reactstrap';

export class ViewTransactions extends Component {
  static displayName = ViewTransactions.name;

  constructor(props) {
    super(props);
      this.state = { transactions: [], filteredtrans: [], loading: true };
  }

  componentDidMount() {
      var isCofareAdmin = window.localStorage.getItem('isCofareAdmin');

      if (isCofareAdmin != 'true') {
          this.props.history.push("/login");
      } else {
          this.populateTransactionData();
      }
   }

    changeFilter = () => {
        var paymentOnly = document.getElementById("ckbfilter") && document.getElementById("ckbfilter").checked;
        if (paymentOnly) {

            var filtereddata = [];
            var data = this.state.transactions;
            for (var i = 0; i < data.length; i++) {
                var transact = data[i];
                if (transact.eventType == 'PAYMENT') {
                    filtereddata.push(transact);
                }
            }
            this.setState({ transactions: this.state.transactions, filteredtrans: filtereddata, loading: false });
        } else {
            this.setState({ transactions: this.state.transactions, filteredtrans: this.state.transactions, loading: false });
        }
    }
    reconcileData = () => {
        var xhr = new XMLHttpRequest()

        xhr.addEventListener('load', () => {
            var data = JSON.parse(xhr.responseText);
            var filtereddata = [];
            for (var i = 0; i < data.length; i++) {
                var transact = data[i];
                if (transact.eventType == 'PAYMENT') {
                    filtereddata.push(transact);
                }
            }
            this.setState({ transactions: data, filteredtrans: filtereddata, loading: false });
            alert('Recent transactions that are found on cofare.io were marked "OK". Please refresh page to see the change.');
        })

        // cannot do this unless the resource has header: <add name="Access-Control-Allow-Origin" value="*" />
        //xhr.open('GET', 'https://cofare.io/CoFare/allJsonService!recentTransactionIds.cofare');
        //do it from server code instead
        xhr.open('GET', 'reconcile');
        xhr.send();       
    }

    static renderTransactionsTable(transactions) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Date</th>
            <th>Source</th>
            <th>Transaction ID</th>
            <th>Customer Email</th>
            <th>Transaction Amount</th>
            <th>Event Type</th>
            <th>Event Data</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
                {transactions.map(transaction =>
                    <tr key={transaction.date} style={{ color: transaction.status === "OK" ? "green" : "red" }}>
                  <td>{transaction.datetime}</td>
                  <td>{transaction.eventSrc}</td>
                  <td>{transaction.transactionId}</td>
                  <td>{transaction.customerEmail}</td>
                  <td>{transaction.amount}</td>
                  <td>{transaction.eventType}</td>
                  <td>{transaction.eventData}</td>
                  <td>{transaction.status}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

    render() {
      var isCofareAdmin = window.localStorage.getItem('isCofareAdmin');

      if (isCofareAdmin != 'true') {
          return (
              <div>
                  <p>Please <a href='login'>Login</a> </p>
              </div>
          );
      } else {
          let contents = this.state.loading
              ? <p><em>Loading...</em></p>
              : ViewTransactions.renderTransactionsTable(this.state.filteredtrans);

          return (
              <div>
                  <h1 id="tabelLabel" >Transaction List</h1>
                  <p>This is a list of transactions recorded by the monitor server. If a certain transaction ID is found here but not on the Cofare server, it is a charge for which cofare.io wasn't able to record the sale. It should be refunded to the customer because we don't know what they were trying to buy.</p>
                  <input id="ckbfilter" type="checkbox" defaultChecked onChange={this.changeFilter} /> Show Payment Only &nbsp;&nbsp;&nbsp;
                  <input type="button" value="Reconcile with cofare.io" onClick={this.reconcileData} /> &nbsp;&nbsp;&nbsp;
                  {contents}
              </div>
          );
      }
  }

    async populateTransactionData() {
      const response = await fetch('viewtransactions');
      const data = await response.json();

      var filtereddata = [];
      for (var i = 0; i < data.length; i++) {
          var transact = data[i];
          if (transact.eventType == 'PAYMENT') {
              filtereddata.push(transact);
          }
      }
      this.setState({ transactions: data, filteredtrans: filtereddata, loading: false });
    }

}
